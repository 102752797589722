import { priceImg } from '../../utils/utils';

export interface PriceItemListProps {
  service: string;
  description: string;
  consist: string[];
  price: number[];
  additionaly: string[];
  imgSrc: string;
}
const priceItemList: PriceItemListProps[] = [
  {
    service: 'ТОТАЛЬНОЕ ВОССТАНОВЛЕНИЕ',
    description:
      'Холодный уход направлен на работу изнутри волоса. ' +
      'На этом этапе восстанавливается внутренняя целостность волоса, возвращается прочность и эластичность. ' +
      'Горячая процедура - завершающий этап. Создаёт защитную плёнку на волосах для продления эффекта. ' +
      'Придает эстетический вид. ',
    consist: [
      'Многоступенчатая холодная реконструкция (для самых повреждённых волос)',
      'Холодная + горячая/теплая техника',
    ],
    price: [2500],
    additionaly: [
      'Процедуру возможно подобрать для волос с любой степенью повреждения. ',
      'Эффект сохраняется 1 - 3 месяца. ',
      'Услуга учитывает длинну и густоту волос, примерный расчёт можно произвести в калькуляторе на сайте',
    ],
    imgSrc: priceImg[4],
  },
  {
    service: 'ХОЛОДНОЕ ВОССТАНОВЛЕНИЕ',
    description:
      'Устраняет сухость и питает. ' +
      'Способствует удержанию влаги, сокращает сечение. ' +
      'Даёт визуальную плотность и густоту. ' +
      'Волосы шелковистые и блестят. ' +
      'Облегчает расчёсывание. ' +
      'Процедура не меняет структуру волоса. Не выпрямляет и не разглаживает. ' +
      'Подходит для повреждённых, окрашенных, осветленных, ослабленных волос. ' +
      'Рекомендуется делать курсом. ' +
      'Имеет накопительный эффект. ',
    consist: ['Детокс очищение волос', 'Протеиновое восстановление', 'Липидное восстановление', 'Ламинирующий шаг'],
    price: [1600, 2500],
    additionaly: [
      'Результат сохраняется до 1 месяца. ',
      'Подравнивание кончиков бесплатно',
      'Процедура не имеет противопоказаний',
      'Подходит для волос с любой степенью повреждения',
      'Можно делать беременным и при ГВ',
    ],
    imgSrc: priceImg[2],
  },
  {
    service: 'SPA УХОД',
    description:
      'С его помощью можно предотвратить сечение прядей, устранить сухость или наоборот, повышенную жирность. ' +
      'Восстановить, напитать, увлажнить и укрепить волосы.',
    consist: [
      'Детокс очищение волос',
      'Консультация и диагностика',
      'Пилинг кожи головы',
      'Восстановление внутренней целостности волос',
      'Процедура SPA класса',
      'Уход за кончиками',
      'Питательная маска',
    ],
    price: [2300, 3500],
    additionaly: [
      'Рекомендуется делать курсом',
      'Результат сохраняется до 1 - 1,5 месяца',
      'Имеет накопительный эффект',
      'Подравнивание кончиков бесплатно',
      'Процедура не имеет противопоказаний',
    ],
    imgSrc: priceImg[3],
  },
  {
    service: 'КОЛЛАГЕНОВОЕ ОБЁРТЫВАНИЕ',
    description:
      'Процедура максимально глубокого увлажнения. ' +
      'Подходит для любой степени повреждения волос. ' +
      'Восстанавливает эластичность. ' +
      'Даёт эффект термозащиты. ' +
      'Восстанавливает водный баланс. ' +
      'Снимает статику. ' +
      'Обеспечивает гладкость и блеск. ' +
      'Облегчает расчесывание. ',
    consist: ['Мягкое очищение волос', 'Коллагеновое обёртование', 'Липидное восстановление'],
    price: [1400, 1800],
    additionaly: [
      'Имеет накопительный эффект',
      'Результат сохраняется до 1 месяца',
      'Рекомендуется делать курсом',
      'Подравнивание кончиков бесплатно',
      'Процедура не имеет противопоказаний',
      'Подходит для волос с любой степенью повреждения',
      'Можно делать беременным и при ГВ',
    ],
    imgSrc: priceImg[1],
  },
  {
    service: 'БОТОКС',
    description:
      'Процедура по восстановлению и питанию волос. ' +
      'Не выпрямляет волосы. Имеет эффект разглаживания. ' +
      'Делает волосы более плотными, мягкими, послушными. Придаёт блеск и шелковистость. ' +
      'Облегчает расчесывание. ' +
      'Устраняет ломкость и статику. ',
    consist: [
      'Глубокое очищение волос',
      'Холодный этап (подложка) при необходимости',
      'Ботокс в тёплой или горячей технике',
      'Питательная маска',
    ],
    price: [1600, 2500],
    additionaly: [
      'Эффект сохраняется 2 - 4 месяца',
      'Процедура имеет противопоказания',
      'Требуется консультация и диагностика повреждения волос',
      'Подравнивание кончиков бесплатно',
      'Использование подложки (холодный этап перед ботоксом) 500 - 1500. Зависит от степени повреждения волос.',
      'Услуга учитывает длинну и густоту волос, примерный расчёт можно произвести в калькуляторе на сайте',
    ],
    imgSrc: priceImg[0],
  },
  {
    service: 'КЕРАТИНОВОЕ ВЫПРЯМЛЕНИЕ',
    description:
      'Процедура долговременного изменения структуры волоса. ' +
      'Основная задача - выпрямление. ' +
      'Дополнительный эффект: Блеск, гладкость, уплотнение волоса, послушность. ',
    consist: [
      'Глубокое очищение волос',
      'Холодный этап (подложка) при необходимости',
      'Кератиновое выпрямление',
      'Питательная маска',
    ],
    price: [2300],
    additionaly: [
      'Эффект сохраняется 3 - 6 месяцев',
      'Процедура имеет противопоказания',
      'Требуется консультация и диагностика повреждения волос',
      'Использование подложки (холодный этап перед кератиновым выпрямлением) 500 - 1500. Зависит от степени повреждения волос.',
      'Подравнивание кончиков бесплатно',
      'Услуга учитывает длинну и густоту волос, примерный расчёт можно произвести в калькуляторе на сайте',
    ],
    imgSrc: priceImg[5],
  },
];

export default priceItemList;
